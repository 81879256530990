* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  background: #ffffff;
}

body {
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  background: #fff;
}

a {
  color: #2b895c;
}

a:hover {
  color: #000;
}

#root {
  display: flex;
  flex-direction: column;
}

.app-content {
  flex: 1 0 auto;
}

.page-content {
  padding: 4.4rem 0 0;
  background: #ffffff;
}

.page-content .content {
  border-left: 1px solid #EAECF0;
  padding: 25px 25px 0;
  background: #F7F8FA;
  width: 100%;
}

.page-content .full-height {
  min-height: calc(100vh - (120px + 4.4rem));
}

.slide-description {
  color: white;
  padding: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 5px #000;
}

.slide-description .title {
  font-size: 3rem;
  margin: auto;
  line-height: 1;
}

.slide-description .description {
  font-size: 1.5rem;
}

header a:hover {
  text-decoration: none;
  color: inherit;
}

.box-shadow-danger {
  box-shadow: 0 0 .5rem #f00 !important;
}

/* RISK ASSESSMENT MATRIX */

.risk-assessment-matrix {
  border: 1px solid #fff !important;
  font-size: .8rem;
}

.risk-assessment-matrix td {
  border: 1px solid #fff;
  padding: .75rem;
}

.risk-assessment-matrix .big {
  font-size: 1rem;
}

.risk-assessment-matrix .gray {
  background: #d9d9d9;
}

.risk-assessment-matrix .orange {
  background: #fc0;
}

.risk-assessment-matrix .red {
  background: #f00;
  color: #fff;
}

.risk-assessment-matrix .green {
  background: #9c6;
}

.rating-text {
  font-size: 17px !important;
  font-weight: bold;
}

.home-page {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #778596;
}

.home-page footer {
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
}

.DigiCert_Wrap {
  padding-top: 25px;
}

.home-page-content h3 {
  font-size: 1.2em;
  margin: 0;
}

.home-page-content img {
  margin: 1rem 0;
}

.home-page-content p {
  margin: 0;
  color: #666;
}

img.table-image {
  max-width: 150px;
  max-height: 150px;
}

.crud-sections-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.crud-sections-header h4, .crud-sections-header h5, .crud-sections-header a, .crud-sections-header button {
  display: inline-block;
}

.crud-sections-header a, .crud-sections-header button {
  margin-left: auto;
}

a:hover {
  text-decoration: none;
}

.more {
  position: absolute;
  right: .5rem;
  top: .5rem;
  margin: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  text-align: right;
  color: #28804B;
}

.more:hover {
  color: #000000;
}

.calendar-prev-next-buttons {
  display: flex;
  align-items: center;
}

.calendar-prev-next-buttons span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: #3A3A3A;
  margin: 0 20px;
}

.calendar-prev-next-buttons button {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 3px;
  display: flex;
}

.calendar-prev-next-buttons button img {
  margin: auto;
}

.locations-tool-tip {
  height: 100%;
  padding-top: 23px;
}

.rating-stars {
  min-width: 110px;
  margin: 0;
}

.rating-stars i {
  font-size: x-large;
}

.finances-tooltip span {
  margin-top: 35px;
}

.finances-page-tooltip span {
  margin-left: 5px;
}

.finances-page-tooltip {
  display: flex;
  align-items: center;
}

.crud-sections-header span {
  text-align: center;
}

.profile-card th {
  width: 100px;
}

.crud-sections-specialisms span {
  text-align: center;
  align-items: center;
  margin-bottom: -5px;
  height: 100%;
  padding-top: 3px;
  padding-left: 10px;
}

.subtypes {
  margin-left: 30px;
}

.separated-list h6, .separated-list p {
  margin-bottom: 0;
}

form.specialism .action-button {
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
}

.separator {
  display: block;
  width: 100%;
  height: 20px;
}

.child-level {
  margin-left: 30px;
}

.specialism-level span:first-child {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.specialism-level span:last-child {
  display: inline-block;
  width: auto;
}

.rbc-month-view {
  height: 466px !important;
}

.tester-list-item .picture {
  width: 131px;
  height: 120px;
  margin-bottom: 10px;
}

.tester-list-item .picture img {
  max-width: 100%;
  max-height: 100%;
}

.star-icon {
  height: 25px;
  width: 25px;
}

.calendar-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /*border-bottom: 1px solid #EAECF0;*/
}

.month-year-buttons {
  display: flex;
  overflow: hidden;
  border-radius: 3px;
}

.month-year-buttons button, .calendar-btn-today, .btn-calendar-this-year {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  padding: 7px 10px;
  background: #FFFFFF;
  color: #28804B;
  border: 1px solid #EAECF0;
}

.month-year-buttons :disabled {
  background: #28804B;
  border: 1px solid #28804B;
  color: #ffffff;
}

.calendar {
  width: calc(100% + 3px);
  border-collapse: separate;
  border-spacing: 1px;
  table-layout: fixed;
  background: #EAECF0;
  margin: 0 0 -1px -1px;
}

.calendar.year td {
  width: calc(100% / 12);
}

.calendar td {
  width: calc(100% / 7);
  padding-top: 40px;
  text-align: left;
  vertical-align: top;
}

.calendar .calendar-names {
  vertical-align: middle;
  background: none;
  text-align: center;
  height: 2rem !important;
  padding: 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
  color: #3A3A3A;
  background: #ffffff;
}

.calendar tr th {
  padding: 15px 0;
}

.calendar tr th:last-child {
  text-align: right;
}

.calendar-cell {
  height: 75px;
  position: relative;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #626C78;
}

.other-month {
  background: #ffffff;
}

.date-number {
  top: 4px;
  left: 4px;
  position: absolute;
  text-align: center;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #626C78;
  background: transparent;
}

.date-number.today {
  background: #28804B;
  color: #ffffff;
}

.other {
  color: rgba(119, 133, 150, 0.5);
}

.close-month-calendar {
  text-align: right;
  margin-bottom: .5rem;
}

.react-calendar {
  border: 0 !important;
}

.calendar .empty-event {
  padding: .5rem;
  height: 34px;
  display: block;
}

.calendar .event, .calendar .year-event, .modal-event {
  padding: .5rem;
  margin: 0 4px 4px;
  display: block;
  line-height: 1;
  background: rgba(71, 157, 77, 0.1);
}

.calendar .event a, .calendar .year-event a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar .event {
  margin: 0 0 4px 0;
}

.calendar .event-oneday {
  margin: 0 4px 4px 4px;
}

.calendar .event-start {
  margin: 0 0 4px 4px;
}

.calendar .event-end {
  margin: 0 4px 4px 0;
  color: rgba(71, 157, 77, 0.1);
}

.calendar .year-event {
  text-overflow: ellipsis;
}

.year-event a, .event a {
  display: block;
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #28804B;
}

.year td {
  vertical-align: top;
  min-width: 100px;
  padding-top: 10px;
}

.month td {
  height: 120px;
}

.filename {
  width: 270px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.datepicker-custom {
  z-index: 9500;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 50px;
  transform: translate(-50%, -50%);
}

.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.select-field-search {
  display: block;
  position: relative;
}

.close-select {
  display: block;
  position: absolute;
  bottom: 2px;
  right: 18px;
  cursor: pointer;
}

.specialisms-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.status-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #626C78;
}

.status-block {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-block span {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.qr-code-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* TEST DETAILS TABLE */

.test-details-table {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 20px 25px;
}

.test-details-table table {
  width: 100%;
}

.test-details-table td {
  font-style: normal;
  line-height: normal;
  padding-bottom: 15px;
  vertical-align: top;
}

.test-info-header {
  padding-right: 25px;
  font-weight: bold;
  font-size: 13px;
  color: #3A3A3A;
  width: 20%;
}

.test-details-table td:first-child {
  padding-right: 25px;
  font-weight: bold;
  font-size: 13px;
  color: #3A3A3A;
  width: 20%;
}

.test-details-table td:last-child {
  font-weight: normal;
  font-size: 14px;
  color: #778596;
  width: 80%;
}

.test-details-header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: #28804B;
  margin: 0 0 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #EAECF0;
}

.block-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  margin: 0;
  color: #3A3A3A;
}

/* CLIENT FAQ */

.client-faq-block {
  max-width: 750px;
}

.question-header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #28804B;
  margin: 25px 0;
}

.author-name {
  font-weight: 600;
  font-size: 14px;
  color: #626C78;
}

.question-block .me {
  font-weight: 600;
  font-size: 14px;
  color: #479D4D;
}

.answer {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #778596;
  margin: 20px 0 0;
}

.tester-certificates {
  font-size: 18px;
}

.profile-item-label {
  font-size: 0.875rem;
}

.tester-certificate {
  color: #479D4D;
  border-bottom: 1px dashed #479D4D;
  cursor: pointer;
}

.certificate-modal_img {
  max-width: 100%;
  max-height: 100%;
}

/* CUSTOM BUTTONS */

.main-content-header {
  font-weight: bold;
  font-size: 22px;
  color: #3A3A3A;
  margin: 0 0 20px;
}

.btn-back {
  font-size: 14px;
  text-decoration-line: underline;
  color: #479D4D;
  border: 0;
  background: transparent url("/public/images/back-icon.svg") 0 50% no-repeat;
  padding-left: 20px;
  cursor: pointer;
}

.btn-disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

.btn-need-help {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #479D4D;
  margin-left: 10px;
}

.btn-tester,
.label-tester {
  background-color: white;
  cursor: pointer;
  color: #778596;
  border: 0;
  font-size: 14px;
  font-weight: 600;
}

.label-tester {
  cursor: inherit;
}

.btn-view {
  background: transparent url("/public/images/view-icon.svg") 10px 50% no-repeat;
  border: 0;
  padding-left: 40px;
  cursor: pointer;
  color: #778596;
  font-size: 13px;
  font-weight: 600;
}

.btn-manage {
  background: transparent url("/public/images/star-icon.png") 10px 50% no-repeat;
  border: 0;
  padding-left: 40px;
  padding-top: 40px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.btn-manage-inactive {
  background: transparent url("/public/images/star-icon-grey.svg") 10px 50% no-repeat;
  border: 0;
  padding-left: 40px;
  padding-top: 40px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.profile-picture .btn-manage {
  background: transparent url("/public/images/star-icon.png") 10px 50% no-repeat;
  border: 0;
  cursor: pointer;
  padding-top: 0px;
  font-size: 13px;
  font-weight: 600;
}

.btn-book-a-tester-small {
  max-width: 125px;
  max-height: 34px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(80deg, #2B895C 100%, #479D4D 0%);
  border: 0;
  padding: 8px 14px;
  cursor: pointer;
  color: #ffffff;
}

.btn-accept, .btn-search, .btn-book-a-tester, .btn-continue, .btn-book, .btn-save, .btn-export {
  text-transform: uppercase;
  background: linear-gradient(45deg, #479d4d, #2b895c);
  border: 0;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.btn-accept:hover, .btn-search:hover, .btn-book-a-tester:hover, .btn-book:hover, .btn-save:hover, .btn-book-a-tester-small:hover, .btn-export:hover {
  background: linear-gradient(45deg, #2b895c, #479d4d);
}

.btn-accept:disabled, .btn-search:disabled, .btn-book-a-tester:disabled, .btn-book:disabled, .btn-save:disabled, .btn-export:disabled {
  background: linear-gradient(45deg, #cccccc, #999999);
  color: #000000;
}

.btn-edit, .btn-reject, .btn-view-icon, .btn-cancel, .btn-cancel-default, .btn-refresh, .btn-delete, .btn-invoice-details, .btn-schedule-a-test, .btn-new, .btn-extend, .btn-remove {
  border: 0;
  padding: 5px 10px 5px 30px;
  cursor: pointer;
  color: #778596;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  min-height: 20px;
}

.btn-edit:hover, .btn-reject:hover, .btn-view-icon:hover .btn-cancel:hover, .btn-refresh:hover, .btn-cancel-default:hover, .btn-delete:hover, .btn-invoice-details:hover, .btn-schedule-a-test:hover, .btn-new:hover, .btn-extend:hover, .btn-remove:hover {
  color: #778596;
}

.btn-cancel-default {
  padding: 5px 10px;
  background: transparent;
}

.btn-edit {
  background: transparent url("/public/images/edit-icon.svg") 0 50% no-repeat;
}

.btn-reject {
  background: transparent url("/public/images/reject-icon.svg") 0 50% no-repeat;
}

.btn-cancel {
  background: transparent url("/public/images/cancel-icon.svg") 0 50% no-repeat;
}

.btn-delete {
  background: transparent url("/public/images/delete-icon.svg") 0 50% no-repeat;
}

.btn-invoice-details {
  background: transparent url("/public/images/invice-details-icon.svg") 0 50% no-repeat;
}

.btn-refresh {
  background: transparent url("/public/images/refresh.svg") 0 50% no-repeat;
}

.btn-schedule-a-test {
  background: transparent url("/public/images/schedule-a-test-icon.svg") 0 50% no-repeat;
}

.btn-new {
  background: transparent url("/public/images/new-icon.svg") 0 50% no-repeat;
}

.btn-remove {
  background: transparent url("/public/images/minus-icon.svg") 0 50% no-repeat;
}

.btn-view-icon {
  background: transparent url("/public/images/view-icon.svg") 5px 50% no-repeat;
}

.btn-download {
  background: transparent url("/public/images/download-icon.svg") 0 50% no-repeat;
  border: 0;
  padding-left: 40px;
  cursor: pointer;
  color: #28804B;
  font-size: 14px;
  font-weight: 600;
}

.btn-download-pdf {
  background: transparent url("/public/images/download-pdf-icon.svg") 0 50% no-repeat;
  font-weight: 600;
  font-size: 14px;
  color: #28804B;
  padding-left: 26px;
  border: 0;
  cursor: pointer;
}

.btn-extend {
  background: transparent url("/public/images/extend-icon.svg") 0 50% no-repeat;
}

/* TEST DETAILS TABLE */

.document-block-title {
  font-weight: bold;
  font-size: 13px;
  color: #3A3A3A;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.document-block, .styled-block, .test-block, .risk-chart-block, .guidance-block, .booking-proposal-block, .test-details-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 20px 25px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #778596;
  overflow: hidden;
}

.test-block table {
  width: 100%;
}

.test-block table td {
  vertical-align: top;
  padding-right: 25px;
  width: 33.3333%;
}

.block-table {
  width: 100%;
  margin-top: 10px;
}

.block-table td {
  border-top: 1px solid #EAECF0;
  padding: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #778596;
}

.block-table td.td-download {
  text-align: left;
}

.block-table td.td-date {
  text-align: right;
}

.block-table td.td-delete {
  text-align: right;
  min-width: 90px;
}

.table-no-items {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #778596;
}

.guidance-block {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #778596;
}

.guidance-block h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: #3A3A3A;
}

.styled-modal {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 20px 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #778596;
  margin: auto;
}

.tester-modal .styled-block {
  border: none;
}

.styled-modal .styled-modal-body {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.styled-modal h4 {
  font-weight: bold;
  font-size: 22px;
  color: #3A3A3A;
  margin: 0 0 20px;
}

.styled-modal p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 20px;
  color: #778596;
}

.styled-modal-text {
  color: #000;
  flex-basis: 20%;
  flex-grow: 1;
}

.styled-modal-text.fb10 {
  flex-basis: 10%;
}

.styled-modal-text.fb30 {
  flex-basis: 30%;
}

.styled-modal-text-link {
  flex-basis: 30%;
  flex-grow: 1;
}


.styled-modal.xs {
  max-width: 280px;
}

.styled-modal.sm {
  max-width: 480px;
}

.styled-modal.md {
  max-width: 680px;
}

.styled-modal.lg {
  max-width: 960px;
}

.styled-modal.xl {
  max-width: 1024px;
}

.styled-modal .or-text {
  margin: 0 5px;
  float: right;
  min-height: 36px;
  display: flex;
  align-items: center;
}

.tester-review h5 {
  font-weight: bold;
  font-size: 20px;
  color: #3A3A3A;
}

.tester-review p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  color: #778596;
}

/* TESTER VIEW */
.tester-view .profile-picture {
  max-width: 120px;
}

.tester-view-wrapper {
  width: 1000px;
}

.tester-view p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  color: #778596;
}

.tester-view .insurance span {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: normal;
}

.tester-view h2 {
  font-weight: bold;
  font-size: 20px;
  color: #3A3A3A;
}

.tester-view h5 {
  font-weight: bold;
  font-size: 13px;
  color: #3A3A3A;
  margin: 20px 0 10px;
}

.tester-view h5 span {
  font-size: 14px;
  font-weight: normal;
  color: #479D4D;
  cursor: pointer;
}

.tester-view .specialism-item {
  background: rgba(71, 157, 77, 0.1);
  display: inline-block;
  border-radius: 3px;
  font-size: 14px;
  color: #28804B;
  padding: 3px 10px;
  margin: 0 5px 5px 0;
}

.tester-view .day-rate {
  font-weight: 300;
  font-size: 16px;
  color: #778596;
  margin-right: 10px;
  min-width: 80px;
  text-align: right;
}

.day-rate .btn-manage {
  padding-top: 4px;
}

.tester-view .day-rate strong {
  font-weight: 600;
  color: #3A3A3A;
}

.tester-view .star-rating-rank {
  margin: 7px 0 50px 7px;
  font-size: 14px;
  color: #778596;
}

.admin-tester-view img {
  width: 100%;
}

.dbs .dbs-list {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

.dbs .dbs-list li {
  margin: 0 0 10px 0;
  padding: 0 0 0 30px;
  background: url("/public/images/v.svg") 0 50% no-repeat;
  font-weight: 600;
  font-size: 14px;
  color: #626C78;
}

.styled-table table {
  width: 100%;
  border-top: 1px solid #EAECF0;
}

.styled-table .btn-tester {
  color: #626C78;
  background: none;
  padding: 0;
}

.styled-table .btn-tester img {
  border-radius: 50%;
  max-height: 40px;
  max-width: 50px;
}

.styled-table .tester-cell {
  color: #2b895c;
  text-decoration: underline;
}

.styled-table th {
  font-weight: 600;
  font-size: 14px;
  color: #3A3A3A;
  padding: 12px;
  border-bottom: 1px solid #EAECF0;
}

.styled-table td {
  font-size: 14px;
  color: #626C78;
  padding: 12px;
  border-bottom: 1px solid #EAECF0;
}

.styled-table a {
  color: #2b895c;
  text-decoration: underline;
}

.styled-table a:hover {
  color: #3a3a3a;
}

.styled-table .notification {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: rgba(71, 157, 77, 0.1);
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 18px;
  color: #28804B;
  margin: auto;
}

.styled-table .form-input {
  margin-top: 16px;
}

.styled-table .total {
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #3A3A3A;
}

.tester-image-name {
  display: flex;
  align-items: center;
}

.tester-image-name img {
  border-radius: 50%;
  margin-right: 15px;
  max-width: 40px;
  width: 40px;
  display: block;
  height: 40px;
  object-fit: cover;
}

.styled-table .tester-image-name .author-name {
  white-space: nowrap;
}

.styled-table th.rating, .styled-table td.rating {
  text-align: center;
}

.styled-table td.rating {
  font-size: 24px;
}

.styled-table .date {
  font-weight: 300;
  font-size: 14px;
  color: #2B895C;
}

/* Reformatted SideNav styles */

.side-nav {
  padding: 20px 0;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  width: auto;
}

.side-nav a {
  color: #778596;
  padding: 17px 25px;
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 16px;
}

.side-nav a:hover {
  color: #000000;
}

.side-nav a:hover i.material-icons {
  color: #000000;
}

.side-nav a.active, .side-nav a.active:hover {
  /*background: #f8f8f8;*/
  color: #3A3A3A;
}

.side-nav i.material-icons {
  margin-right: 10px;
}

.side-nav .active .material-icons {
  color: #2b895c;
}

.styled-tabs {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0 auto 30px;
  display: flex;
  border-bottom: 2px solid #EAECF0;
}

.styled-tabs .tab {
  margin-top: 2px;
  padding: 10px 20px;
  display: block;
  font-size: 16px;
  color: #3A3A3A;
  cursor: pointer;
}

.styled-tabs .active {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #479D4D;
  margin-bottom: -2px;
  border-bottom: 2px solid #479D4D;
}

.project-location-item {
  min-height: 20px;
  border: none;
  background: transparent url("/public/images/location-icon.svg") 0 50% no-repeat;
  padding-left: 24px;
  margin: 7px;
  word-break: break-word;
}

.chart-top {
  text-align: center;
  color: #778596;
}

.chart-top h2 {
  margin: 0;
  line-height: 1;
  font-weight: 300;
  font-size: 32px;
}

.chart-top span {
  margin: 0;
  line-height: 1;
  font-weight: 300;
  font-size: 13px;
}

.underlined-link {
  text-decoration: underline;
}

.underlined-link--white {
  color: #FFFFFF;
  text-decoration: underline;
}

.wysiwyg-wrapper h4 {
  font-weight: normal;
}

.wysiwyg-wrapper a {
  text-decoration: underline !important;
  color: #28804B !important;
}

.wysiwyg-iframe {
  border: 1px solid #eeeeee;
  width: 100%;
  height: 300px;
}

.booking-proposal-view {
  background: rgba(71, 157, 77, 0.1);
  margin-top: 20px;
  padding: 20px;
}

.booking-proposal-view td:first-child, .booking-proposal-view th:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  color: #28804B;
  padding-left: 0;
  padding-right: 0;
}

.booking-proposal-view-header {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  color: #28804B;
}

.booking-proposal-view table {
  border: 0;
}

.booking-proposal-view th,
.booking-proposal-view td {
  border-bottom: 1px solid #c9e3cd;
}

.booking-proposal-view tr:first-child th,
.booking-proposal-view tr:first-child td {
  border-top: 0;
}

.booking-proposal-view td:last-child, .booking-proposal-view th:last-child {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  color: #626C78;
}

.booking-proposal-view .booking-proposal-item {
  margin: 10px 10px 10px 0;
}

.booking-proposal-view .additional-service {
  margin: 20px 10px 20px 0;
}


.booking-proposal-view .additional-service-item {
  margin: 10px 0 -15px 10px;
  width: 100%;
  max-width: 100px;
}

.additional-service-item:nth-child(1) {
  max-width: 250px
}

.additional-service-item:nth-child(2) {
  max-width: 410px
}

.additional-service-item:nth-child(3) {
  max-width: 70px
}

.note-text {
  margin-top: 20px;
  color: black;
}

.array-button {
  height: 40px !important;
  width: 40px !important;
  background: red !important;
}

.array-button--xs {
  height: 30px !important;
  width: 30px !important;
  background: red !important;
}

.booking-proposal-view b {
  color: black;
  margin-right: 10px;
}

.editor-class {
  height: 300px;
  background: #ffffff;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .styled-modal {
    margin: auto auto;
  }
}

/* RISK ASSESSMENT MATRIX */

@media (max-width: 768px) {
  .slide-description .title {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .slide-description .description {
    font-size: 1rem;
    line-height: 1;
  }
}

.ck-content {
  height: 400px;
  min-height: 100%;
  overflow-y: auto;
}

.rounded-profile-search {
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;

}

.rounded-profile-select {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.span-in-select {
  padding-left: 13px;
}

.risk-treatment-comment {
  max-width: 250px;
}

.risk-treatment-comment:hover {
  cursor: pointer;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #3A3A3A;
}

.risk-treatment-comment.full {
  max-height: 300px;
  overflow: auto;
  width: 250px;
}

.word-break-all {
  word-break: break-all;
}

.test-details-profile-picture {
  min-width: 110px;
}

.text-dark-gray {
  color: #3A3A3A
}

.no-opacity {
  opacity: 1 !important;
}

.styled-block.chart {
  height: calc(100% - 1.5rem - 1px);
}

.rounded-image {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.rounded-image img {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
}

.tester-profile-note {
  font-size: 12px;
  margin-left: 175px;
}

.select-option-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.select-option-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: 0;
  outline: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.select-option-list-item {
  text-align: left;
}

.form-text-wrap {
  min-height: 48px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  font-size: 18px;
  color: #000;
  justify-content: flex-end;
}

.days-error-field {
  transform: translateY(-10px);
}

.days-error-field > div:before,
.days-error-field > div:after,
.days-error-field > input:after {
  display: none;
}

.timesheet-form-item-required p {
  margin-bottom: 0;
}

.timesheet-row-selected, .timesheet-row-selected .timesheet-cell-action__button, .timesheet-table thead tr {
  background-color: #efefef;
  transition: 300ms;
}

.timesheet-search-form__wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}

.timesheet-search-form__item {
  flex: 1 1 190px;
}

.timesheet-form h5 {
  color: #28804b;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 5px;
}

.timesheet-form-section {
  /*border-top: 1px solid #eaecf0;*/
  margin: 0 0 15px;
  padding-top: 10px;
}

.timesheet-search-form__refresh {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
}

.timesheet-search-form__refresh button {
  font-size: 14px;
  font-weight: 600;
  margin-left: 0;
}

.timesheet-select-all-approved-label {
  color: #778596;
  font-size: 14px;
  font-weight: 600;
}

.timesheet-info-message {
  font-size: 14px;
  color: #ff0000;
}

.timesheet-week-heading {
  font-weight: 600;
  font-size: 16px;
  color: #3A3A3A;
  margin: 0 0 10px;
}

.timesheet-table th, .timesheet-table td {
  padding: 5px 10px;
  border-right: 1px solid #e0e0e0;
  font-size: 14px;
  color: #000;
}

.timesheet-table thead tr th:last-child, .timesheet-table tbody tr td:last-child {
  border-right: 0;
}

.timesheet-table th {
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

.timesheet-cell-checkbox {
  width: 30px;
}

.timesheet-cell-notes {
  min-width: 200px;
  max-width: 300px;
}

td.timesheet-cell-notes > button, .show-more-cell > button {
  border: 0;
  background: transparent;
  padding-left: 0;
}

td.timesheet-cell-notes > button span, .show-more-cell > button span {
  color: #2b895c;
}

.timesheet-notes-status {
  text-transform: capitalize;
  font-weight: 400;
}

.timesheet-notes-status.timesheet-status--invoiced,
.timesheet-notes-status.timesheet-status--approved,
.timesheet-notes-status.timesheet-status--rejected,
.timesheet-notes-status.timesheet-status--submitted,
.timesheet-notes-status.timesheet-status--edited {
  font-weight: 500;
}

th.timesheet-cell-week-day, td.timesheet-cell-week-day {
  width: 60px;
  text-align: center;
}

th.timesheet-cell-total, td.timesheet-cell-total {
  text-align: center;
  background-color: #efefef;
}

th.timesheet-cell-week-day.weekend {
  color: #eda4ad;
}

td.timesheet-cell-week-day, td.timesheet-cell-total, td.timesheet-cell-po-number {
  position: relative;
}

td.timesheet-cell-po-number {
  max-width: 200px;
}

.timesheet-day-cell-tooltip {
  font-size: 12px;
  line-height: 1.3;
}

.timesheet-day-cell-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timesheet-cell-add button:hover,
.timesheet-cell-edit button:hover,
.timesheet-cell-action__button button:hover {
  background-color: transparent;
}

.timesheet-cell-action__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffe8;
}

.timesheet-cell-action__button a {
  margin: 0;
  background-position: 50%;
}

.timesheet-cell-action {
  transition: 300ms;
}

.timesheet-day-cell-wrap > .timesheet-cell-action, .timesheet-po-number-cell-wrap > .timesheet-cell-action {
  visibility: hidden;
  opacity: 0;
}

.timesheet-day-cell-wrap:hover .timesheet-cell-action, .timesheet-po-number-cell-wrap:hover > .timesheet-cell-action {
  visibility: visible;
  opacity: 1;
}

.timesheet-po-number-cell-value {
  display: inline-block;
  word-break: break-word;
  padding: 5px;
  width: 100%;
  min-height: 30px;
}

.timesheet-status--approved {
  color: #2b895c;
}

.timesheet-status--rejected {
  color: #ff0000;
}

.error-color {
  color: #ff0000 !important;
}

.timesheet-status--invoiced {
  color: #ffcc00;
  font-weight: 600;
  transition: 300ms;
}

button .timesheet-status--invoiced:hover, button.timesheet-status--invoiced:hover {
  color: #d5ab00;
}

.timesheet-status-link {
  text-decoration: underline;
  margin-left: 0;
  padding: 0;
}

.timesheet-status-link--invoice {
  color: #2b895c;
}

.timesheet-status-link--invoice:hover {
  color: #205f3f;
}


.search-by-month-form {
  width: 100%;
  max-width: 350px;
}

.timesheet-checkbox.disabled {
  cursor: not-allowed !important;
}

.time-expenses-view-item__value {
  color: #3a3a3a;
}

.form-field-hidden > *:before, .form-field-hidden > *:after {
  border-bottom: 0 !important;
}

.form-field-hidden > p {
  margin-bottom: 0 !important;
}

.consultant-project-select > div,
.consultant-project-select > div > p,
.select-margin-0 > div,
.select-margin-0 > div > p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.document-repeater {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.document-repeater__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: auto;
  min-width: 200px;
}

.document-repeater__name {
  width: 100%;
  min-width: 150px;
}

.document-repeater__remove {
  /*padding-top: 15px;*/
}

.view-document-item {
  margin-bottom: 10px;
  padding: 0;
}

.view-document-item .btn-cancel-default {
  margin-left: 0;
  padding-left: 0;
}

.styled-modal.year-month-picker-modal {
  padding: 0;
  border: 0;
}

.year-month-picker {
  display: flex;
  flex: 1 1 auto;
  background-color: #2b895c;
  padding: 0 15px 10px;
  margin-bottom: 15px;
  align-items: center;
  gap: 15px;
}

.picker-year {
  width: 90px;
  height: 145px;
  overflow-y: scroll;
}

.picker-year::-webkit-scrollbar {
  display: none;
}

.picker-year {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.picker-year .picker-year__item {
  color: #fff;
  font-weight: 400;
  transition: 300ms;
  justify-content: center;
  border-bottom: 1px solid #39a16e;
}

.picker-year .picker-year__item.selected {
  font-weight: 600;
}

.picker-year .picker-year__item.selected, .picker-year .picker-year__item:hover, .picker-year .picker-year__item:focus {
  background-color: #39a16e !important;
}

.year-month-picker__month {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: calc(100% - 90px);
  min-width: 280px;
  margin: 0 auto;
  padding-top: 10px;
}

.year-month-picker__month span {
  min-width: 50px;
  text-align: center;
}

.year-month-picker__month span > div span {
  font-weight: 600;
  justify-content: center;
}

.year-month-picker__month span.selected > div {
  background-color: #39a16e;
}

.year-month-picker__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
  padding: 10px 15px;

}

.year-month-picker__actions button {
  margin-left: 0;
  text-transform: uppercase;
}

.year-month-picker__actions button:first-child {
  margin-right: auto;
}

.year-month-picker-input {
  border-bottom: 1px solid #9e9e9e;
  margin-bottom: 8px;
}

.project-repeater__percent {
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
}

.project-repeater__project .select-field-search > div {
  margin-top: 8px;
}

.string-break-word {
  overflow-wrap: break-word;
}


@media (max-width: 768px) {
  .tester-profile-note {
    margin-left: 145px;
  }
}

.project-repeater__row {
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
}

.project-repeater__row .project-repeater__project {
  max-width: 435px;
  width: 100%;
  padding-right: 15px;
}

.project-repeater__row .project-repeater__project-rate {
  max-width: 170px;
  width: 100%;
  padding-right: 15px;
}

.project-repeater__row .project-repeater__row-cell:not(:last-child) {
  padding-right: 15px;
}

.project-repeater__row .project-repeater__days-stats {
  height: 100%;
  align-self: end;
  margin-bottom: 15px;
}


@media (max-width: 767px) {
  .repeater-remove-button-wrap {
    order: 2;
    padding-top: 15px !important;
  }
}

@media (max-width: 560px) {
  .timesheet-search-form__item {
    flex: 1 1 100%;
  }

  .search-by-month-form {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .flex-column-in-mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .full-width-in-mobile {
    width: 100%;
  }
}

.consultant-fields > .consultant-fields__card {
  margin-top: 2rem;
}

.documents-repeater .btn-new {
  margin-top: 20px;
  margin-left: 40px;
}

.documents-repeater .btn-remove {
  margin-left: 5px;
}

.documents-repeater__info {
  position: relative;
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.documents-repeater__info .error-color {
  margin-top: 2px;
  padding-left: 45px;
  position: absolute;
  top: 100%;
}

.documents-repeater .select-field-search  .select-field-search__error {
  position: absolute;
  top: 100%;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  display: inline-block;
  vertical-align: middle;
}
