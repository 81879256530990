.menu-nav {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.menu-item {
  color: black;
  padding: 3px;
}

.three-dots:after {
  cursor: pointer;
  color: black;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdown-container div{
  text-decoration: none;
  color: black;
  padding-left: 6px;

}

.dropdown-container .dropdown-item {
  color: rgba(0, 0, 0, 0.87);
  width: auto;
  height: 24px;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: content-box;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  line-height: 1.5em;
  white-space: nowrap;
}

.dropdown {
  position: absolute;
  right: 10px;
  background: white;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
  padding: 2px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
  cursor: pointer;
}

.dropdown-container:hover {
  background: rgba(210, 210, 210, 0.3);
  border-radius: 50%;
}

.dropdown-container{
  position: relative;
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
}