* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.top {
  background-size: cover;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 0;
  /*box-shadow: 0px 10px 24px 0px rgba(15, 88, 152, 0.08);*/
  box-shadow: 0 10px 24px rgba(15, 88, 152, 0.08) !important;
  background: #fff !important;
  z-index: 2;
}

.header .logo {
  height: 41px;
  width: 168px;
}

.header .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
}

.header-home {
  background-color: #000;
}

.header-home .header {
  background: #fff;
}


.register-button-wrapper {
  display: flex;
  flex-direction: row;
}

.register-button-wrapper span{
  display: flex;
  align-items: center;
  color: #8191a5;
  margin-right: 10px;
  font-size: 14px;
}

.register-button {
  background: linear-gradient(45deg, #479d4d, #2b895c);
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 7px 25px;
  transition: all ease .5s;
}

.register-button:hover {
  color: #ffffff;
  background: linear-gradient(45deg, #2b895c, #479d4d);
}

/* ROLE SWITCHER */

.role-switcher .links {
  margin: auto;
  display: flex;
  justify-content: center;
}

.role-switcher .link {
  padding: 10px 20px;
  display: block;
  font-weight: normal;
  font-size: 16px;
  color: #3A3A3A;
  border-bottom: 2px solid #EAECF0;
  cursor: pointer;
}

.role-switcher .link.active {
  border-bottom: 2px solid #479D4D;
}

/* ROLE SWITCHER */

section.content {
  padding: 0;
  text-align: center;
  position: relative;
}

section.content .row {
  text-align: center;
}

.content-img {
  max-width: 80px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

section.content h2 {
  margin: 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;

  color: #3A3A3A;
}

section.content p {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

footer {
  margin-top: 50px;
  font-size: 14px;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  text-align: center;
}

footer .container {
  text-align: center;
  display: block;
}

footer .wrapper {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #ddd;
}

footer, footer a {
  color: #8191a5;
  text-decoration: none;
}

footer a:hover {
  color: #2b895c;
}

footer .navigation {
  margin: 20px 0;
  display: block;
  align-items: center;
  align-self: right;
}

footer .navigation a {
  margin: 10px auto 0;
  display: block;
}

footer .linkedin img {
  filter: grayscale(100%);
  display: block;
  width: 30px;
  transition: all ease .3s;
  margin: auto;
}

footer .linkedin img:hover {
  filter: grayscale(0%);
}

.log-in-button {
  padding: 15px 30px;
  display: block;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(45deg, #479d4d, #2b895c);
  margin: 50px auto;
  min-width: 180px;
  transition: all ease .3s;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.log-in-button .arrow-right {
  margin: 0 0 2px 10px;
  display: inline-block;
}

.log-in-button:hover {
  color: #fff;
  background: linear-gradient(45deg, #2b895c, #479d4d);
}

.avord-gradient {
  background: linear-gradient(45deg, #479d4d, #2b895c);
  color: #fff;
}

.cookies {
  position: fixed;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 20px 0;
  font-size: 13px;
  box-shadow: 0 0 24px rgba(15, 88, 152, 0.08);
}

.cookies .text a {
  color: #44a64f;
}

.cookies .accept-button {
  background: #000000;
  margin-top: 10px;
  display: inline-block;
  color: #fff;
  padding: 8px 25px;
  text-decoration: none;
}

/* STYLES FOR ABOUT, PRIVACY, TERMS */

.content-management {
  background: #fff;
  color: #464646;
}

.content-management h2,
.content-management h3,
.content-management h4,
.content-management p {
  margin-bottom: 20px;
}

.content-management h2,
.content-management h3,
.content-management h4 {
  margin-top: 40px;
}

.content-management h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #000;
  margin: 40px 0;
}

.content-management h1 .date {
  font-family: "Open Sans", sans-serif;
  color: #8191a5;
  font-size: 1rem;
  font-weight: normal;
}

.content-management h2 {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .1rem;
}

.content-management h3 {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
}

.content-management h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #999;
}

.content-management p {
  font-family: "Open Sans", sans-serif;
}

.content-management .section {
  color: forestgreen;
}

.content-management ul {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.content-management ul li {
  font-family: "Open Sans", sans-serif;
  margin: .5rem 0;
}

/* STYLES FOR ABOUT, PRIVACY, TERMS */
/* 2019-03-19 "About AVORD" page new design implementation */

section.new-about {
  text-align: center;
  margin-bottom: 70px;
}

section.new-about h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: normal;
  color: #3A3A3A;
  margin: 60px 0 25px;
}

section.new-about .video-block {
  background: #000000;
}

section.new-about h2 {
  margin: 0;
  color: #28804B;
  line-height: 30px;
  font-size: 22px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
}

section.new-about h3 {
  color: #3a3a3a;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0;
}

section.new-about p {
  font-size: 14px;
  line-height: 21px;
  color: #626C78;
  margin: 0;
}

section.new-about .video-block img {
  display: block;
}

section.new-about.objectives {
  margin-bottom: 30px;
}

section.new-about.organise {
  margin-bottom: 20px;
}

section.new-about.organise img {
  margin: 0;
}

section.new-about.tests .test-card {
  text-align: left;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 1rem;
}

section.new-about.tests h4 {
  padding: 0 0 0 36px;
  margin: 0 0 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: normal;
}

section.new-about.tests ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

section.new-about.tests ul li {
  background: url("/public/images/v.svg") top left no-repeat;
  padding: 0 0 0 36px;
  margin: 0 0 14px;
  font-size: 14px;
  color: #778596;
}

section.new-about.tester-profiles p {
  width: 75%;
  margin: 0 auto;
}

section.new-about.making-nations {
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 46px 1rem;
  color: #ffffff;
  margin: 0;
  background: url("/public/images/hero-image.jpg") 50% 50%;
  background-size: cover;
}

section.new-about.confidentiality {
  margin-bottom: 90px;
}

/* HOME PAGE 2019-03-20 */

.hp-first-paragraph {
  text-align: center;
  max-width: 700px;
  margin: 50px auto;
  padding: 0 1rem;
}

.slider {
  background: linear-gradient(45deg, #479d4d, #2b895c);
  max-width: 1000px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}

.slider .prev, .slider .next {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: url("/public/images/arrow-right.svg") 50% 50% no-repeat;
  background-size: 50%;
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
  color: #ffffff;
}

.slider .prev {
  left: -50px;
  transform: translate(-150%, 150%) rotate(180deg);
  background-color: #479d4d;
}

.slider .next {
  right: -50px;
  transform: translate(150%, 150%);
  background-color: #2b895c;
}

.homepage-main-btns-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-main-btns-group .btn-about {
  margin: 10px;
  padding: 12px 30px;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  font-weight: 600;

  background: linear-gradient(45deg, #479d4d, #2b895c);
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease .5s;
}

.homepage-main-btns-group .btn-about:hover {
  background: linear-gradient(45deg, #2b895c, #479d4d);
}

.homepage-main-btns-group .btn-play-video {
  background: rgba(12, 18, 14, 0.8) url("/public/images/play.svg") 30px 50% no-repeat;
  border: 2px solid #2B895C;
  margin: 10px;
  padding: 10px 30px 10px 50px;
  color: #2B895C;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  font-weight: 600;
}

.hero-block {
  margin-top: 61px;
  height: 350px;
  display: flex;
  text-align: center;
  background: url("/public/images/hero-image.jpg") 50% 50% no-repeat;
  background-size: cover;
}

.hero-block-content {
  margin: auto;
}

.hero-block-content h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 42px;
  line-height: normal;
  letter-spacing: 0.02em;
  margin: 0 0 40px;
}

.how-avord {
  padding: 90px 0 0;
  background: linear-gradient(180deg, #FAFBFC 0%, rgba(250, 251, 252, 0) 100%);
}

.how-avord h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: normal;

  color: #28804B;
}

.how-avord p {
  max-width: 350px;
}

.bringing-clarity {
  background: linear-gradient(180deg, rgba(234, 236, 240, 0) 0%, #EAECF0 100%);
  text-align: center;
}

.bringing-clarity h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #3A3A3A;
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
}

.bringing-clarity img {
  display: block;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

/* HOW AVORD */

.how-avord .how-avord-helps {
  margin-top: 0;
  display: block;
  width: 100%;
  max-width: 732px;
}

.how-avord h2 {
  margin-bottom: 40px;
}

.how-avord h2:last-child {
  margin-top: 80px;
}

.how-avord p {
  margin-bottom: 40px;
}

.how-table-block {
  /*display: flex;*/
  /*height: 100%;*/
}

.how-table-image {
  display: block;
  width: 100%;
  /*height: 100%;*/
  max-width: 1120px;
  margin: 0 auto;
}

.left-side-image {
  display: none;
  width: 100%;
}

.right-side-image {
  display: none;
  width: 100%;
}

.how-table {
  display: none;
  font-size: 16px;
  line-height: 24px;
  min-width: 1140px;
}

.how-table .container {
  min-width: 1140px;
}

.how-table .bg-row {
  background: url("/public/images/how-table-bg.png") 50% 0 repeat-y;
  margin-bottom: 10px;
}

.how-table .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;

  color: #626C78;
  margin-bottom: 15px;
}

.how-table .head-left {
  padding-right: 105px;
  text-align: right;
}

.how-table .head-center {
  text-align: center;
}

.how-table .head-right {
  padding-left: 105px;
  text-align: left;
}

.colored-left, .colored-center, .colored-right {
  padding: 5px;
}

.colored-left div, .colored-center div, .colored-right div {
  height: 120px;
  display: flex;
}

.colored-left div span, .colored-center div span, .colored-right div span {
  margin: auto;
  padding: 1rem 0;
}

.colored-left div {
  background: url("/public/images/v2.svg") 90% 50% no-repeat;
  text-align: right;
  padding-left: 50px;
  padding-right: 100px;
}

.colored-center div {
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #3A3A3A;
  text-align: center;
}

.colored-right div {
  background: url("/public/images/x.svg") 10% 50% no-repeat;
  text-align: left;
  padding-left: 100px;
  padding-right: 50px;
}

/* STEPS */

.home-steps {
  padding: 100px 0;
  text-align: center;
}

.home-steps .logo {
  width: 170px;
}

.home-steps h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #3A3A3A;

  margin: 10px 0 60px;
}

.steps {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.step {
  margin: 0 40px 40px;
}

.step img {
  display: block;
  width: 100%;
  max-width: 300px;
}

.role-content {
  margin-bottom: 100px;
}

.friends {

}

.friends-bg {
  height: 130px;
  background: url("/public/images/friend-background.png") 50% 50%;
  background-size: cover;
}

.friends .img-wrapper {
  margin-top: -150px;
}

.friends img {
  display: block;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

.once {
  max-width: 700px;
  padding: 0 1rem;
  margin: -30px auto 0;
  text-align: center;
  color: #3A3A3A;
}

@media (min-width: 768px) {
  .homepage-main-btns-group {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  section.new-about {
    text-align: left;
  }

  section.new-about .left-column {
    text-align: right;
  }

  section.new-about.tests p {
    width: 65%;
    margin: 0 auto;
  }

  section.new-about.organise img {
    margin: -30px 0;
  }

  section.new-about.tester-profiles p {
    width: 60%;
  }

  section.new-about.making-nations {
    font-size: 26px;
  }

  .how-avord .how-avord-helps {
    margin-top: -70px !important;
  }

  .hp-first-paragraph {
    padding: 0;
  }

  .header {
    justify-content: center;
  }

  .header .logo {
    justify-self: center;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }

  section.content {
    text-align: left;
    margin-bottom: 50px;
  }

  section.content .row {
    text-align: center;
  }
  
  footer {
    margin-top: 50px;
    font-size: 14px;
    bottom: 0;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
  }

  footer .wrapper {
    padding: 15px 0;
    border-top: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  footer .navigation {
    display: flex;
    align-items: center;
    align-self: right;
    margin: 0;
  }

  footer .navigation a {
    margin: 0 0 0 30px;
  }

  .cookies .container {
    display: flex;
    align-items: center;
  }

  .cookies .accept-button {
    margin: 0 0 0 25px;
  }
}

@media (min-width: 1024px) {
  .how-table {
    display: block;
  }

  .bringing-clarity img {
    display: block;
    margin: -60px auto;
    max-width: 1440px;
    width: 100%;
  }

  .bringing-clarity h2 {
    font-size: 26px;
  }
}

@media (min-width: 1140px) {
  .left-side-image {
    display: block;
    width: 100%;
  }

  .right-side-image {
    display: block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  section.new-about.tests .test-cars-wrapper {
    display: flex;
    justify-content: center;
  }

  section.new-about.tests .test-card {
    width: 280px;
    height: 260px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 5px;
    padding: 30px;
    margin: 30px;
  }
}